import React from "react";
import { useHistory } from "react-router";
import { EuiBottomBar, EuiFlexGroup, EuiLink, EuiText } from "@elastic/eui";
import { FormattedMessage } from "react-intl";

const Footer = () => {
    const history = useHistory();

    const isModifiedEvent = (event) =>
        !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

    const isLeftClickEvent = (event) => event.button === 0;

    const onClick = (event, to) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.target.getAttribute("target")) {
            return;
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return;
        }

        event.preventDefault();

        history.push(to);
    };

    return (
        <footer>
            <EuiBottomBar>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiLink onClick={(e) => onClick(e, "/termsOfService")}>
                        <FormattedMessage id="Footer.termsOfService" />
                    </EuiLink>
                    <EuiLink onClick={(e) => onClick(e, "/privacyPolicy")}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                    </EuiLink>
                    <EuiText>{`Copyright © ${new Date().getFullYear()} Octaos AS - All rights reserved`}</EuiText>
                    <EuiLink onClick={(e) => onClick(e, "/useOfCookies")}>
                        <FormattedMessage id="Footer.useOfCookies" />
                    </EuiLink>
                    <EuiLink onClick={(e) => onClick(e, "/about")}>
                        <FormattedMessage id="Footer.about" />
                    </EuiLink>
                </EuiFlexGroup>
            </EuiBottomBar>
        </footer>
    );
};

export default Footer;
