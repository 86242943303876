import React, { Fragment, useState } from "react";
import {
    EuiSpacer,
    EuiText,
    EuiPageTemplate,
} from "@elastic/eui";
import { FormattedMessage, useIntl } from "react-intl";

import { resetPasswordConfirm } from "../../api/services/user";

import EuiLinkButton from "../../components/EuiLinkButton";
import PasswordForm from "../../components/PasswordForm";

const ResetPasswordConfirm = ({
    match: {
        params: { code },
    },
}) => {
    const intl = useIntl();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleResetPassword = (newPassword) => {
        setLoading(true);
        setError(null);

        resetPasswordConfirm(code, newPassword)
            .then(({ data }) => {
                if (!data.success) {
                    setError("general");
                } else {
                    setIsSuccess(true);
                }
            })
            .catch((error) => {
                if (
                    error?.status === 404 &&
                    error?.data?.msgKey === "invalid_reset_token"
                ) {
                    setError("invalidResetToken");
                } else {
                    setError("general");
                }
            })
            .then(() => setLoading(false));
    };

    return (
        <Fragment>
            <EuiPageTemplate restrictWidth panelled >
                <EuiPageTemplate.Header
                    pageTitle={intl.formatMessage({
                        id: "ResetPasswordConfirm.title",
                    })}
                />
                <EuiPageTemplate.Section alignment="center" grow>
                    {isSuccess ? (
                        <Fragment>
                            <EuiText>
                                <h3>
                                    <FormattedMessage id="ResetPasswordConfirm.success" />
                                </h3>
                            </EuiText>

                            <EuiSpacer />

                            <EuiLinkButton to="/login">
                                <FormattedMessage id="ResetPasswordConfirm.goToLogin" />
                            </EuiLinkButton>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {error && error === "invalidResetToken" ? (
                                <Fragment>
                                    <EuiText>
                                        <h3>
                                            <FormattedMessage id="ResetPasswordConfirm.Errors.invalidResetToken" />
                                        </h3>
                                    </EuiText>

                                    <EuiSpacer />

                                    <EuiLinkButton to="/resetPassword">
                                        <FormattedMessage id="ResetPasswordConfirm.goToResetPassword" />
                                    </EuiLinkButton>
                                </Fragment>
                            ) : (
                                <div style={{ width: 400 }}>
                                    <PasswordForm
                                        loading={loading}
                                        error={error}
                                        resetError={() => setError(null)}
                                        onClick={handleResetPassword}
                                    />
                                </div>
                            )}
                        </Fragment>
                    )}
                </EuiPageTemplate.Section>
            </EuiPageTemplate>
        </Fragment>
    );
};

export default ResetPasswordConfirm;
