import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { EuiProvider } from '@elastic/eui';

import './index.css';
import App from './App';
import OctaosLoader from './components/OctaosLoader';

import './helpers/firebase';

const environment = typeof process.env.REACT_APP_SENTRY_ENVIRONMENT !== "undefined" ? process.env.REACT_APP_SENTRY_ENVIRONMENT : "local";

Sentry.init({
    dsn: "https://57544081c3ec439488d661e22c31045f@o1156151.ingest.sentry.io/6240878",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment,
});

const ThemeProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [isDark, setIsDark] = useState(localStorage.getItem("theme") === "dark");

    useEffect(() => {

        const setTheme = async () => {
            setLoading(true)

            const theme = localStorage.getItem("theme")
            const isDark = theme === "dark"

            if (isDark) {
                await import('@elastic/eui/dist/eui_theme_dark.css')
            } else {
                await import('@elastic/eui/dist/eui_theme_light.css')
            }

            setIsDark(isDark)
            setLoading(false)
        }

        setTheme()

    }, [])

    return (
        <EuiProvider colorMode={isDark ? 'dark' : 'light'} >
            {loading ? (
                <div style={{ marginTop: 100 }} ><OctaosLoader /></div>
            ) : (
                children
            )}
        </EuiProvider>
    )
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<ThemeProvider><App /></ThemeProvider>);
