import React, { Fragment, useState } from 'react';
import {
    EuiBasicTable,
    EuiFormRow,
    EuiSkeletonText,
} from '@elastic/eui';
import { useIntl, FormattedMessage } from 'react-intl';

import useApi from '../../api/useApi'
import { getUserEmails, deleteUserEmail } from '../../api/services/user'
import ConfirmDialog from '../../components/ConfirmDialog';
import SetEmailModal from './SetEmailModal';
import ResendModal from './ResendModal';

const EmailAddresses = ({ user, updateEmails, setUpdateEmails }) => {
    const intl = useIntl()

    const [loading, error, emails] = useApi(() => getUserEmails(user.id), [user, updateEmails], user)

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentDeleteEmail, setCurrentDeleteEmail] = useState(null)

    const Placeholder = () => <EuiSkeletonText lines={1} style={{ width: 100 }} />

    const columns = [
        {
            field: 'email',
            name: intl.formatMessage({ id: "Dashboard.EmailAddresses.email" }),
            render: email => loading ? <Placeholder /> : (
                <span>{email}</span>
            )
        },
        {
            field: 'isOctaosId',
            name: '',
            render: (isOctaosId, item) => loading ? <Placeholder /> : (
                <span>
                    {isOctaosId && <FormattedMessage id="Dashboard.EmailAddresses.octaosID" />}

                    {!isOctaosId && item.verified && (
                        <SetEmailModal user={user} email={item.email} />
                    )}

                    {!isOctaosId && !item.verified && (
                        <ResendModal user={user} email={item.email} />
                    )}
                </span>
            )
        },
        {
            field: 'verified',
            name: intl.formatMessage({ id: "Dashboard.EmailAddresses.status" }),
            render: verified => loading ? <Placeholder /> : (
                <span>
                    <FormattedMessage id={`Dashboard.EmailAddresses.${verified ? "verified" : "awaitingVerification"}`} />
                </span>
            )
        },
        {
            name: '',
            actions: [
                {
                    name: intl.formatMessage({ id: "Dashboard.EmailAddresses.delete" }),
                    description: intl.formatMessage({ id: "Dashboard.EmailAddresses.deleteThisEmail" }),
                    icon: 'trash',
                    color: 'danger',
                    type: 'icon',
                    onClick: item => {
                        setIsModalVisible(true)
                        setCurrentDeleteEmail(item.email)
                    },
                    isPrimary: true,
                    'data-test-subj': 'action-delete',
                    available: item => !item.isOctaosId && !loading
                },
            ],
        },
    ];

    const items = loading ? [{ email: "", verified: false, isOctaosId: false }] : emails && !error ? emails : []

    const handleDelete = async () => {
        await deleteUserEmail(user.id, currentDeleteEmail)
        setUpdateEmails(!updateEmails)
        setCurrentDeleteEmail(null)
    }

    return (
        <Fragment>
            <EuiBasicTable
                items={items}
                columns={columns}
                hasActions
                loading={loading}
            />
            <EuiFormRow fullWidth helpText={intl.formatMessage({ id: "Dashboard.EmailAddresses.helpText" })} >
                <span></span>
            </EuiFormRow>
            {isModalVisible && (
                <ConfirmDialog
                    title={intl.formatMessage({ id: "Dashboard.EmailAddresses.DeleteDialog.title" })}
                    body={intl.formatMessage({ id: "Dashboard.EmailAddresses.DeleteDialog.body" }, { email: currentDeleteEmail })}
                    closeModal={() => setIsModalVisible(false)}
                    onConfirm={handleDelete}
                />
            )}
        </Fragment>
    );
}

export default EmailAddresses;
