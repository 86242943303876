export const isDarkTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") === "dark" : false

export const getApiUrl = () => {
    let apiUrl = "http://localhost:3000";

    if (process.env.NODE_ENV === "production") {
        if (process.env.REACT_APP_OSC_API_URL) apiUrl = process.env.REACT_APP_OSC_API_URL
        else apiUrl = "https://api.octaos.com";
    }

    return apiUrl;
}
