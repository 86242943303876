import React, { Fragment, useState } from "react";
import {
    EuiTitle,
    EuiTextColor,
    EuiSpacer,
    EuiPageTemplate,
} from "@elastic/eui";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";

import { setPassword } from "../../api/services/user";
import PasswordForm from "../../components/PasswordForm";
import { isAuthenticated } from "../../helpers/auth";

const SetPassword = () => {
    const history = useHistory();
    const intl = useIntl();

    if (!isAuthenticated()) history.push("/");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSetPassword = (newPassword) => {
        setLoading(true);
        setError(null);

        setPassword(newPassword)
            .then(({ data }) => {
                if (!data.success) {
                    setError("general");
                    setLoading(false);
                } else {
                    history.push("/");
                }
            })
            .catch((error) => {
                if (error?.status === 403) {
                    setError("api_access");
                } else {
                    setError("general");
                }

                setLoading(false);
            });
    };

    return (
        <Fragment>
            <EuiPageTemplate restrictWidth panelled>
                <EuiPageTemplate.Header
                    pageTitle={intl.formatMessage({ id: "SetPassword.title" })}
                />

                <EuiPageTemplate.Section alignment="center" grow>
                    {error && (
                        <Fragment>
                            <EuiTitle>
                                <h4>
                                    <EuiTextColor color="danger">
                                        <FormattedMessage
                                            id={`SetPassword.Errors.${error}`}
                                        />
                                    </EuiTextColor>
                                </h4>
                            </EuiTitle>

                            <EuiSpacer />
                        </Fragment>
                    )}

                    <div style={{ width: 400 }}>
                        <PasswordForm
                            isSetPassword
                            loading={loading}
                            error={error}
                            resetError={() => setError(null)}
                            onClick={handleSetPassword}
                        />
                    </div>
                </EuiPageTemplate.Section>
            </EuiPageTemplate>
        </Fragment>
    );
};

export default SetPassword;
