import React from "react";
import { IntlProvider } from "react-intl";
import { flatten } from "flat";

import en from "./translations/en.json";
import nb from "./translations/nb.json";

// import nb from 'react-intl/locale-data/nb';

// addLocaleData(nb)

const MyIntlProvider = ({ code, children }) => {
  const getTranslation = () => {
    switch (code) {
      case "en":
        return en;
      case "nb":
        return nb;
      default:
        return en;
    }
  };

  const translations = getTranslation();

  return (
    <IntlProvider
      key={code}
      locale={code}
      messages={flatten(translations.messages)}
    >
      {children}
    </IntlProvider>
  );
};

export default MyIntlProvider;
